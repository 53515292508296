.ReactModal__Overlay.ReactModal__Overlay--after-open.level-item {
  position: absolute !important;
}

.ReactModal__Content.ReactModal__Content--after-open.level-item {
  inset: 0 !important;
}

.level-item-clicked {
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}
